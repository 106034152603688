import { useGetAllPlansListQuery } from "../../../api/planEndpoints"
import { TripPlanCard } from "./trip-plan-card/TripPlanCard"

export const TripPlans = () => {
    const { data, error, isFetching, isLoading } = useGetAllPlansListQuery()
    return (
        <>
        <div className="p-2">
                {error ? (<>Oh no, there was an error</>) :
        isLoading ? (<>Loading...</>) :
        data ? (
            <>
              { data.content.map((plan:any, index:number) => (
        <TripPlanCard plan={plan} key={index} />
    )
        
)}
           </>   ) :
           null
       } 
       </div>
        </>
    )
}