import { useParams } from "react-router-dom";
import { useGetCompanyByEntityQuery } from "../../../api/companyEndpoints";
import { ShowEntity } from "../../show-entity/ShowEntity";

export const TripCompany = () => {

    const { id } = useParams()
    const { data: company, error, isFetching, isLoading } = useGetCompanyByEntityQuery(id!)

    console.log("company is...")
    console.log(company)
    return(
        <>
             {error ? (<>Oh no, there was an error</>) :
        isLoading ? (<>Loading...</>) :
        company ? (<>
        <div className="show-wrapper">

            <ShowEntity entity={company?.entity} />

            <div className="entity-buttons">

                <div className="e-button">
                    Products
                </div>
                <div className="e-button">
                    Location
                </div>
                <div className="e-button">
                    Impact
                </div>
            </div>
        </div>
        </>) : null}
        </>
    )
}


