import { GreenPractice } from "../green-practice/GreenPractice"

export const GreenPractices = (props:any) => {

    const practices = props.practices
    return (
        <>
        <div className="practices-container">
            {
                practices.map((practice:any, index:number) => (
                    <GreenPractice practice={practice} key={index}/>
                ))
            }
            
        </div>
        </>
    )
}