import { CameraFoodCard } from "../camera-food-card/CameraFoodCard"

export const CameraFoodCarousel = (props:any) => {

    const setImgSrc = props.setImgSrc
    const setIsSelected = props.setIsSelected
    let foods = [
        {
            id: 1,
            title: "Jolly Roger",
            description: "shepherd's salad",
           
            image: "salad1.jpg"
        },
        {
            id: 2,
            title: "Jolly Roger",
            description: "caprese salad",

            image: "salad2.jpg"
        },
        {
            id: 3,
          title: "Dalyana",
          description: "caprese salad",

          image: "salad3.jpg"
      },
      {
            id: 4,
            title: "Hobbit",
          description: "this is a hobbitish marker",

          image: "mleko.jpeg"
      },
      {
        id: 5,
        title: "Juje",
        description: "this is a dwarvish marker",
      
        image: "crates.jpeg"
    },
    {
        id: 6,
        title: "Hobbit",
        description: "this is a hobbitish marker",
      
        image: "pumpkins.jpeg"
    }
        
    ]
    return(
        <>
        <div className="horizontal-container camera-carousel">
        {foods.map((marker:any, index:number) =>(
     <CameraFoodCard marker={marker} index={index} key={index} setImgSrc={setImgSrc} setIsSelected={setIsSelected}/>
    ))}

        </div>
        </>
    )
}