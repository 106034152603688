
import { CameraOutline, LogoNodejs, Map, RestaurantOutline } from "react-ionicons"

export const CameraTab = () => {
    return(
        <div className="bottom-tab">

                <CameraOutline
                color={'#ffffff'}
              
                height="28px"
                width="28px"
                // onClick={() => alert('Hi!')}
                />
               <div>Camera</div> 
        </div>
    )
}