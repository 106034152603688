import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";

export const TripFoodIngredientMap = () => {

    let locations = [

      {
            id: 4,
            title: "Buffalo Bill",
          location: {
              lat: 42.59630238965195,
              lng: 27.462660620957613
          },
          description: "Buffalo milk, yogurt and chees",
          src: 'images/ux/11-cheese.svg',
          image: "mleko.jpeg"
      },
      {
        id: 5,
        title: "Deni's Tomatoes",
        location: {
            lat: 42.53630238965195,
            lng: 27.552660620957613
        },
        description: "we offer a large variety of tomatoes and basil",
        src: 'images/ux/12-tomato.svg',
        image: "crates.jpeg"
    },
    {
        id: 6,
        title: "Hobbit Herbs",
        location: {
            lat: 42.40630238965195,
            lng: 27.4382660620957613
        },
        description: "Organic herbs and spices",
        src: 'images/ux/13-basil.svg',
        image: "pumpkins.jpeg"
    }
]

  const defaultProps = {
    center: {
      lat:42.5028,
      lng:27.4626
    },
    zoom: 9
  };

  return(

    <APIProvider apiKey={"AIzaSyCjy-5DgFTyC9ra-9DA0N0eGz3EqWB_7no"}>
    <Map
    mapId={'caee23a00e9eccf6'}
      style={{width: '100%', height: '60vh'}}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
    >



  {
    locations.map((item:any, index:number) => (
        <AdvancedMarker 
        position={item.location} 
        key={index}
        onClick={(marker: any) => {console.log("whaaat???")
            console.log(item)
        }}
        >
        <img src={'/' + item.src} width={42} height={42} />
     </AdvancedMarker>
    ))
  }


        </Map>
  </APIProvider>
  )
}