import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Entity } from "../../interfaces/Entity";
import { RootState } from "../../app/store";




const initialState: Entity = {
    id: '',
    name: '',
    description: '', 
    type: 0,
    media: [],
    tags: [],
    reviews: []
}
export const entitySlice = createSlice({
    name: "entitySt",
    initialState,
    reducers: {
        updateEntity: (state, action: PayloadAction<Entity>) =>{
            state.id = action.payload.id
            state.name = action.payload.name
            state.description = action.payload.description
            state.type = action.payload.type
            state.media = action.payload.media
            state.tags = action.payload.tags
            state.reviews = action.payload.reviews
        },
        resetEntity:() => {
            return initialState
        }
    }
})

export const { updateEntity, resetEntity} = entitySlice.actions
export const entitySt = (state: RootState) => state.entitySt
export default entitySlice.reducer