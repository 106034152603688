import { useNavigate, useParams } from "react-router-dom"

import { useGetPlanQuery } from "../../../api/planEndpoints"
import { ByteInPlan } from "../../plan-assembly/byte-in-plan/ByteInPlan"
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { TripPlanMap } from "./trip-plan-map/TripPlanMap"

export const TripPlan = () => {
    const { id } = useParams()
    const { data: plan, error, isFetching, isLoading } = useGetPlanQuery(id!)
    
    let juje = plan?.bytes?.length

  const navigate = useNavigate()
    return(
        <>
        <div className="m-2 footer-container">
           {error ? (<>Oh no, there was an error</>) :
        isLoading ? (<>Loading...</>) :
        plan ? (<>
        <h4 className="mx-2">{plan.title}</h4>
        <div onClick={() => {
         navigate(`/plan-map/${id}`)
        }}>
            see map
        </div>
      
        
        {
            plan.bytes.map((byte:any, index:number) => (
                <ByteInPlan byte={byte} key={index} index={index}/>
            ))
        }
        
        </>) : null}
        </div>
        {/* <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <TripPlanMap />
        </Modal.Body>
      </Modal> */}
        </>
    )
}