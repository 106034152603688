import { useParams } from "react-router-dom"
import { useGetFoodByEntityQuery } from "../../../api/foodEndpoints"
import { ShowEntity } from "../../show-entity/ShowEntity"
import { useState } from "react"
import { TripFoodIngredients } from "./trip-food-ingredients/TripFoodIngredients"
import { TripFoodImpact } from "./trip-food-impact/TripFoodImpact"
import { TripFoodNutrition } from "./trip-food-nutrition/TripFoodNutrition"

export const TripFood = () => {


    const { id } = useParams()
    const { data: food, error, isFetching, isLoading } = useGetFoodByEntityQuery(id!)

    console.log("food is...")
    console.log(food)

    const [view, setView] = useState(1)
    return(
        <>
            {error ? (<>Oh no, there was an error</>) :
        isLoading ? (<>Loading...</>) :
        food ? (<>

        { view == 1 ? (
        <div className="show-wrapper">

            <ShowEntity entity={food?.entity} />

            <div className="entity-buttons">

                <div 
                onClick={() => setView(2)}
                className="e-button">
                    Ingredients
                </div>
                <div
                onClick={() => setView(3)}
                className="e-button">
                    Nutrition
                </div>
                <div 
                 onClick={() => setView(4)}
                className="e-button">
                    Sustainability
                </div>
            </div>
        </div>
        ) : 
        view == 2 ? (
            <TripFoodIngredients setView={setView} />
        ) :
        view == 3 ? (
            <TripFoodNutrition setView={setView} />
        ) :
        view == 4 ? (
            <TripFoodImpact setView={setView} />
        ) :
        null
    }
        </>) : null}
        </>
    )
}