import { useState } from "react"
import { PhotoButton } from "./photo-button/PhotoButton"
import { StillCamera } from "./still-camera/StillCamera"
import { MovingCamera } from "./moving-camera/MovingCamera"

export const TripCamera = () => {

   const [still, setStill] = useState(false)
   const [imgSrc, setImgSrc] = useState()
    return(
        <>
        <div className="main-camera-container">
        {
           still ? (<StillCamera imgSrc={imgSrc} setImgSrc={setImgSrc}/>) : (<MovingCamera setStill={setStill} />)
        }
           
        </div>
        </>
    )
}