import { CO2 } from "./co2/C02"
import { GreenPractices } from "./green-practices/GreenPractices"

export const TripImpact = () => {

    const practices = [
        {
            id: 1,
            name: 'Rainfed agriculture',
            icon: 'rain.gif'
        },
        {
            id: 2,
            name: 'Drip irrigation',
              icon: 'drip.gif'
        },
        {
            id: 3,
            name: 'Drought-resistant crops',
            icon: 'drought.gif'
        },
        {
            id: 4,
            name: 'Np-till farming',
            icon: 'no-till.gif'
        },
        {
            id: 5,
            name: 'Crop residue left in field',
            icon: 'residue.gif'
        },
        {
            id: 6,
            name: 'Crop rotation',
            icon: 'rotation.gif'
        },
        {
            id: 7,
            name: 'Cover crops',
            icon: 'cover.gif'
        },
        {
            id: 8,
            name: 'Compost',
            icon: 'compost.gif'
        },
        {
            id: 5,
            name: 'Autictho-nous chicken breeds',
            icon: 'chick.gif'
        },
        {
            id: 6,
            name: 'Goats fed on natural pastures and shrublands',
            icon: 'goat.gif'
        },
    ]
    return(
        <>
        <CO2 />
        <GreenPractices practices={practices} />
        </>
    )
}