export const CameraIngredient = (props:any) => {
    const src = props.ingredient.src
    const imgSrc = "https://farm2everyone.com/img/front-img/" + props.ingredient.image

    const setImgSrc = props.setImgSrc
   
    const setGifSrc = props.setGifSrc

    const gif = "https://farm2everyone.com/img/gifs/" + props.ingredient.producer

    const handlePress = () => {
         setImgSrc(imgSrc)
         setGifSrc(gif)

         
      
      }
    return(
        <>
           <div className="ing-element" onClick={handlePress}>
    <img src={src} />
    </div>
        </>
    )
}