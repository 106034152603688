import { Button } from "react-bootstrap"
import { useDeleteFunByteMutation } from "../../../../api/funByteEndpoints"
import { Link } from "react-router-dom"

export const FunItem = (props:any) => {
    const item = props.item
    const entity = item.entity

    const [deleteFb, {error:adError, isLoading: adLoading}] = useDeleteFunByteMutation()

    const deleteFunByte = () => {
        //  alert("this should delete")
          
          deleteFb(item.id)
          .unwrap()
          .then((payload) => {
              console.log('fulfilled', payload)
          })
      }


    function seeIfVideo(fileName:string){

        const extension = fileName?.split('.').pop()
        if (extension == 'mp4') {
            return true
        }
        else {
            return false
        }
    }

    const src = entity?.media?.filter((media:any) => media.position == 0)[0]?.src

    let link = '/food/' + entity.id

    if (entity.type == 1) {
        link = '/company/' + entity.id
    } else 

    if (entity.type == 2) {
        link = '/food/' + entity.id
    } else 

    if (entity.type == 3) {
        link = '/product/' + entity.id
    } else 

    if (entity.type == 4) {
        link = '/activity/' + entity.id
    }

    return(
        <>
        
        <div className="fun-item">
            <Link to={link} className="text-decoration-none text-reset">
           <div>
            <div className="fun-item-media">
            {src && seeIfVideo(src) ? (<>
  

  <video src={process.env.REACT_APP_IMG + src} autoPlay loop muted></video> 

 </>) : src && !seeIfVideo(src) ? (<><img src={process.env.REACT_APP_IMG + src} /></>) 
         : null}
      
            </div>
            <div>
                {entity.name}
            </div>
        </div>
        </Link>
        </div>
        </>
    )
}