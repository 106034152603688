export const PhotoButton = (props:any) => {
    const setStill = props.setStill
    const setImgSrc = props.setImgSrc
  
    const handleClick = () => {
        setStill(true);

    }
    return(
        <>
               <div className="photo-button-container">
               <div className="photo-button"
               onClick={() => {handleClick()}}
               >
                </div>
                </div>
        </>
    )
}