import { CameraIngredient } from "../camera-ingredient/CameraIngredient"

export const CameraIngredients = (props:any) => {
    const setImgSrc = props.setImgSrc
    const setGifSrc = props.setGifSrc
    let ingredients = [
        {
            id: 1,
            name: 'tomato',
           src:'/images/ux/12-tomato.svg',
            image: "buffalo-heart.jpg",
            producer: 'lozenets.gif'
        },
        {
            id: 4,
            name: "cheese",
            src:'/images/ux/11-cheese.svg',
            image: "cheese.jpg",
            producer: 'kamenyak.gif'
        },
        {
            id: 6,
            name: "basil",
            src:'/images/ux/13-basil.svg',
            image: "basil.jpg",
            producer: 'zdravko.gif'
        }
    ]
    return(
        <>
        <div className="camera-ing-container">
         {ingredients.map((ingredient:any, index:number) =>(
      <CameraIngredient ingredient={ingredient} key={index} setImgSrc={setImgSrc} setGifSrc={setGifSrc}/>
     
        ))} 
        </div>
        </>
    )
}