import { ByteCard } from "./byte-card/ByteCard"

export const ByteMany = (props:any) => {
const entities = props.entities
console.log("entities are")
console.log(entities)
    return(
        <>
        <div className="w-100 my-2">

            <div className="horizontal-container">

                {
                    entities.map((entity:any, index:number) => (
                        <ByteCard entity={entity} key={index} index={index}/>
                    ))
                }
            </div>
        </div>
        </>
    )

}