export const GreenPractice = (props:any) => {
    const src = "https://farm2everyone.com/img/impact-icons/" + props.practice.icon
    return(
        <>
        <div className="practice-container">
           <img className="practice-img" src={src} />
            <div>{props.practice.name}</div>
        </div>
        </>
    )
}