import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';

import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';


import { Food } from './features/food/Food';
import { NewFood } from './features/new-food/NewFood';
import { FoodDetails } from './features/new-food/food-details/FoodDetails';
import { AllMedia } from './features/all-media/AllMedia';
import { NewCompany } from './features/new-company/NewCompany';
import { EntityMedia } from './features/entity-media/EntityMedia';
import { Companies } from './features/companies/Companies';
import { EditCompany } from './features/edit-company/EditCompany';
import { FoodEdit } from './features/food-edit/FoodEdit';
import { NewProduct } from './features/new-company/new-product/NewProduct';
import { Products } from './features/products/Products';
import { EditProduct } from './features/edit-product/EditProduct';
import { NewActivity } from './features/new-actvity/NewActivity';
import { Activities } from './features/activities/Activities';
import { EditActivity } from './features/edit-activity/EditActivity';
import { NewPlanByte } from './features/new-plan-byte/NewPlanByte';
import { PlanBytes } from './features/plan-bytes/PlanBytes';
import { EditPlanByte } from './features/edit-plan-byte/EditPlanByte';
import { PlanByteEntities } from './features/plan-byte-entities/PlanByteEntities';
import { Fun } from './features/fun/Fun';
import { NewPlan } from './features/new-plan/NewPlan';
import { EditPlan } from './features/edit-plan/EditPlan';
import { PlanAssembly } from './features/plan-assembly/PlanAssembly';
import { Plans } from './features/plans/Plans';
import { Phone } from './features/phone/Phone';
import PlanMap from './features/plan-map/PlanMap';
import { LogoNodejs } from 'react-ionicons';
import { TabBar } from './tab-bar/TabBar';
import { TripFun } from './features/tripbytes/trip-fun/TripFun';
import TripMap from './features/tripbytes/trip-map/TripMap';
import { TripPlans } from './features/tripbytes/trip-plans/TripPlans';
import { TripPlan } from './features/tripbytes/trip-plan/TripPlan';
import { TripImpact } from './features/tripbytes/trip-impact/TripImpact';
import { TripCompany } from './features/tripbytes/trip-company/TripCompany';
import { TripFood } from './features/tripbytes/trip-food/TripFood';
import { TripPlanMap } from './features/tripbytes/trip-plan/trip-plan-map/TripPlanMap';
import { TripCamera } from './features/tripbytes/trip-camera/TripCamera';
import { TripHealth } from './features/tripbytes/trip-health/TripHealth';


function App() {
  return (

<>
<div className='bg-dark'>
  <Row>

  <Col  xs={{span: 12}}  lg={{span: 4}} >
                  
          </Col>
        <Col className="" md={{span: 12}}  lg={{span: 4}} >
                    
                    <div className='phone bg-white p-bottom-2'>
                      <div className='inner-phone'>
                      <TabBar />
                      <div>

                      <Routes>
                        <Route index element={<TripFun />} />
                        <Route path='/map' element={<TripMap />} />
                        <Route path='/plans' element={<TripPlans />} />

                        <Route path='/plan/:id' element={<TripPlan />} />

                        <Route path='/plan-map/:id' element={<TripPlanMap />} />
                        <Route path='/impact' element={<TripImpact />} />

                        <Route path='/company/:id' element={<TripCompany />} />
                        
                        <Route path='/food/:id' element={<TripFood />} />

                        <Route path='/camera' element={<TripCamera />} />
                        <Route path='/health' element={<TripHealth />} />
                      </Routes>
                    
                      </div>
                      
                    </div>
                    </div>
          </Col>
          <Col  xs={{span: 12}}  lg={{span: 4}} >
                  
                  </Col>
  </Row>
</div>
      {/* <Navbar bg="dark" data-bs-theme="dark">
        <Container>
         
          <Nav className="me-auto">
        
           
           
           
            <Nav.Link href="/food">Food</Nav.Link>
            <Nav.Link href="/activities">Activities</Nav.Link>
            <Nav.Link href="/products">Products</Nav.Link>
            <Nav.Link href="/companies">Companies</Nav.Link>
            <Nav.Link href="/plan-bytes">Plan Bytes</Nav.Link>
            <Nav.Link href="/plans">Plans</Nav.Link>
            <Nav.Link href="/">Fun</Nav.Link>


          </Nav>
        </Container>
      </Navbar>
      <Routes>
          <Route index element={<Fun />} />
       
          <Route path='/food' element={<Food />} />
          <Route path='/new-company' element={<NewCompany />} />
          <Route path='/edit-company/:id' element={<EditCompany />} />
          <Route path='/new-food' element={<NewFood />} />
          <Route path='/edit-food/:id' element={<FoodEdit />} />
          <Route path='/food-details/:id' element={<FoodDetails />} />
          <Route path='/all-media/:category?/:id?' element={<AllMedia />} />
          <Route path='/entity-media/:category?/:id?' element={<EntityMedia />} />

          <Route path='/companies' element={<Companies />} />
          <Route path='/new-product' element={<NewProduct />} />
          <Route path='/products' element={<Products />} />
          <Route path='/edit-product/:id' element={<EditProduct />} />

          <Route path='/new-activity' element={<NewActivity />} />
          <Route path='/activities' element={<Activities/>} />
          <Route path='/edit-activity/:id' element={<EditActivity />} />

          <Route path='/new-plan-byte' element={<NewPlanByte />} />
          <Route path='/plan-bytes' element={<PlanBytes/>} />
          <Route path='/edit-plan-byte/:id' element={<EditPlanByte />} />
          <Route path='/plan-byte-entities/:id' element={<PlanByteEntities/>} />

          <Route path='/new-plan' element={<NewPlan />} />
          <Route path='/plans' element={<Plans/>} />
          <Route path='/edit-plan/:id' element={<EditPlan />} />
          <Route path='/plan-assembly/:id' element={<PlanAssembly/>} />
          <Route path='/plan-map/:id?' element={<PlanMap/>} />



          <Route path='/phone' element={<Phone />} />
      </Routes> */}
   </>
  );
}

export default App;
