import { Nav } from "react-bootstrap"
import { CameraTab } from "./camera-tab/CameraTab"
import { FunTab } from "./fun-tab/FunTab"
import { ImpactTab } from "./impact-tab/ImpactTab"
import { MapTab } from "./map-tab/MapTab"
import { PlanTab } from "./plan-tab/PlanTab"

export const TabBar = () => {

    return(
        <div className='tab-bar py-2'>
             <Nav.Link href="/map"><MapTab /></Nav.Link>
             <Nav.Link href="/"><FunTab /></Nav.Link>
             <Nav.Link href="/camera"><CameraTab /></Nav.Link>
             <Nav.Link href="/plans"><PlanTab /></Nav.Link>
             <Nav.Link href="/impact"><ImpactTab /></Nav.Link>
    
        </div>
    )
}