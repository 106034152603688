import React from "react";
import GoogleMapReact from 'google-map-react';

import { AdvancedMarker, APIProvider, Map, Marker, Pin } from "@vis.gl/react-google-maps";

const AnyReactComponent = (props:any) => <div className="media-box"><img src="http://167.99.255.26:8080/uploads/files/1732466839087-file.gif" /></div>;

export default function TripMap(){
console.log("api key is...")
console.log(process.env.GOOGLE_MAPS_API_KEY)
    let locations = [
        {
            id: 1,
            title: "Bistro Kapincho",
            location: {
                lat: 42.57630238965195,
                lng: 27.502660620957613
            },
            description: "Fish and chips by the lake",
            src: 'images/ux/4-bistro.svg',
            image: "taurant.jpeg"
        },
        {
            id: 2,
            title: "Jolly Roger",
            location: {
                lat: 42.48630238965195,
                lng: 27.462660620957613
            },
            description: "Sea food restaurant with a magnificent view of the port",
            src: 'images/ux/5-restaurant.svg',
            image: "chibi-kidz.jpg"
        },
        {
            id: 3,
          title: "Ilievi Bio",
          location: {
              lat: 42.57630238965195,
              lng: 27.442660620957613
          },
          description: "Fresh organic vegetables every day",
          src: 'images/ux/12-tomato.svg',
          image: "crates.jpeg"
      },
      {
            id: 4,
            title: "Buffalo Bill",
          location: {
              lat: 42.59630238965195,
              lng: 27.462660620957613
          },
          description: "Buffalo milk, yogurt and chees",
          src: 'images/ux/11-cheese.svg',
          image: "mleko.jpeg"
      },
      {
        id: 5,
        title: "Deni's Tomatoes",
        location: {
            lat: 42.53630238965195,
            lng: 27.552660620957613
        },
        description: "we offer a large variety of tomatoes and basil",
        src: 'images/ux/12-tomato.svg',
        image: "crates.jpeg"
    },
    {
        id: 6,
        title: "Hobbit Herbs",
        location: {
            lat: 42.40630238965195,
            lng: 27.4382660620957613
        },
        description: "Organic herbs and spices",
        src: 'images/ux/13-basil.svg',
        image: "pumpkins.jpeg"
    }
]

  const defaultProps = {
    center: {
      lat:42.5028,
      lng:27.4626
    },
    zoom: 11
  };

  return (
//     // Important! Always set the container height explicitly
//     <div style={{ height: '100vh', width: '100%' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyCjy-5DgFTyC9ra-9DA0N0eGz3EqWB_7no" }}
//         defaultCenter={defaultProps.center}
//         defaultZoom={defaultProps.zoom}
//       >

// {
//            locations.map((item:any) => (
//             <TripMapMarker
//             key={item.id}
//             lat={item.location.latitude}
//             lng={item.location.lng}
//             src={item.src}
//             // item={item}
//             // noClick={true}
//            />
//            ))
            
//            }
//         <AnyReactComponent
//           lat={42.5028}
//           lng={27.4626}
//           text="Woooohooo"
//         />
//       </GoogleMapReact>
//     </div>

<APIProvider apiKey={"AIzaSyCjy-5DgFTyC9ra-9DA0N0eGz3EqWB_7no"}>
    <Map
    mapId={'caee23a00e9eccf6'}
      style={{width: '100%', height: '100vh'}}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
    >



  {
    locations.map((item:any, index:number) => (
        <AdvancedMarker 
        position={item.location} 
        key={index}
        onClick={(marker: any) => {console.log("whaaat???")
            console.log(item)
        }}
        >
        <img src={'/' + item.src} width={42} height={42} />
     </AdvancedMarker>
    ))
  }


        </Map>
  </APIProvider>
  )
}