import { Company } from "../interfaces/Company";
import { springApi } from "./emptySpringApi";

export const companyApi = springApi.injectEndpoints({
    endpoints:(builder) => ({
        getCompanyList: builder.query<any, void>({ 
            query: () => {
               // const { page, term, tag } = arg;
                const size = 6
                //console.log('arg: ', arg);
                return {
                  url: 'animal/companies',
               //   params: { page, term, tag, size },
                };
              },
              providesTags:['companies']

        }), 
        getCompany: builder.query<any,string>({ 
            query: (id) => ({url:`animal/companies/${id}`}),
            providesTags:['companies']
        }),
        getCompanyByEntity: builder.query<any,string>({ 
          query: (id) => ({url:`animal/companies/entity/${id}`}),
          providesTags:['companies']
      }),
          addCompany: builder.mutation<any, any>({
              query: (newFood) => ({
                  url:`animal/companies`,
                  method: 'POST',
                  body: newFood
              }),
              invalidatesTags:['companies']
  
          }),
          addCompanyMedia: builder.mutation<any, FormData>({
            query: (newMedia) => ({
              url:`animal/company-media`,
              method: 'POST',
              body: newMedia
          }),
          invalidatesTags:['companies']
  
        }),
        deleteCompanyMedia: builder.mutation<any, FormData>({
          query: (newMedia) => ({
            url:`animal/company-media`,
            method: 'PUT',
            body: newMedia
        }),
        invalidatesTags:['companies']
  
      }),
      deleteCompany: builder.mutation({
        query: (id) => ({
          url:`animal/companies/${id}`,
          method: 'DELETE',
          
        }),
        invalidatesTags:['companies']
      }),
      updateCompany: builder.mutation({
        query: (company) => ({
          url:`animal/companies/${company.id}`,
          method: 'PUT',
          body: company
        }),
        invalidatesTags:['foods']
      }),
    })
})
export const {
useGetCompanyListQuery,
useGetCompanyQuery,
useGetCompanyByEntityQuery,
useAddCompanyMutation,
useAddCompanyMediaMutation,
useUpdateCompanyMutation,
useDeleteCompanyMediaMutation,
useDeleteCompanyMutation
} = companyApi