import { Food } from "../interfaces/Food";

import { springApi } from "./emptySpringApi";

export const foodApi = springApi.injectEndpoints({
    endpoints:(builder) => ({
        getFoodList: builder.query<any,{page:number, term:string, tag:string}>({ 
            query: (arg) => {
                const { page, term, tag } = arg;
                const size = 6
                console.log('arg: ', arg);
                return {
                  url: 'animal/food',
                  params: { page, term, tag, size },
                };
              },
              providesTags:['foods']

        }), 
        getAllFoodList: builder.query<any, void>({ 
          query: () => {
             // const { page, term, tag } = arg;
              const size = 6
              //console.log('arg: ', arg);
              return {
                url: 'animal/food/juje',
             //   params: { page, term, tag, size },
              };
            },
            providesTags:['foods']

      }), 
        getFood: builder.query<Food,string>({ 
          query: (id) => ({url:`animal/food/${id}`}),
          providesTags:['foods']
      }),
      getFoodByEntity: builder.query<Food,string>({ 
        query: (id) => ({url:`animal/food/entity/${id}`}),
        providesTags:['foods']
    }),
        addFood: builder.mutation<Food, Food>({
            query: (newFood) => ({
                url:`animal/food`,
                method: 'POST',
                body: newFood
            }),
            invalidatesTags:['foods']

        }),

        addIngredient: builder.mutation<any, any>({
          query: (newIng) => ({
              url:`animal/food-ingredients`,
              method: 'POST',
              body: newIng
          }),
          invalidatesTags:['foods']

      }),
      deleteIngredient: builder.mutation<any, any>({
        query: (newIng) => ({
            url:`animal/food-ingredients`,
            method: 'PUT',
            body: newIng
        }),
        invalidatesTags:['foods']

    }),
    addFoodProduct: builder.mutation<any, any>({
      query: (newIng) => ({
          url:`animal/food-products`,
          method: 'POST',
          body: newIng
      }),
      invalidatesTags:['foods']

  }),
  deleteFoodProduct: builder.mutation<any, any>({
    query: (newIng) => ({
        url:`animal/food-products`,
        method: 'PUT',
        body: newIng
    }),
    invalidatesTags:['foods']

}),

        addFoodMedia: builder.mutation<any, FormData>({
          query: (newMedia) => ({
            url:`animal/food-media`,
            method: 'POST',
            body: newMedia
        }),
        invalidatesTags:['foods']

      }),
      deleteFoodMedia: builder.mutation<any, FormData>({
        query: (newMedia) => ({
          url:`animal/food-media`,
          method: 'PUT',
          body: newMedia
      }),
      invalidatesTags:['foods']

    }),
      addFoodArt: builder.mutation<any, any>({ 
        query: (newMedia) => ({
          url:`food-art`,
          method: 'POST',
          body: newMedia
      }),
          invalidatesTags:['foods']

    }), 

    deleteFoodArt: builder.mutation<any, string>({ 
      query: (foodId) => ({
        url:`food-art/${foodId}`,
        method: 'PUT',
      
    }),
        invalidatesTags:['foods']

  }), 
    updateFood: builder.mutation({
      query: (food) => ({
        url:`animal/food/${food.id}`,
        method: 'PUT',
        body: food
      }),
      invalidatesTags:['foods']
    }),
    deleteFood: builder.mutation({
      query: (id) => ({
        url:`animal/food/${id}`,
        method: 'DELETE',
        
      }),
      invalidatesTags:['foods']
    }),

    //   addFoodImage: builder.mutation<Food, {foodId:string, position:number, file:any}>({
    //     query: (arg) => {
    //       const {foodId, position, file } = arg
    //       return {
    //         url: 'food-images',
    //         params: { foodId, position, file },
    //         method: 'POST',
    //         // headers: {
    //         //   "Content-Type": "multipart/form-data;",
    //         // },
    //         // prepareHeaders: (headers: any) => {
    //         //   headers.set("Content-Type", "multipart/form-data")
    //         //     return headers
    //         // },
    //       };
        
    //     },
    //     invalidatesTags:['foods']

    // })


        })
    })
    export const { 
        useGetFoodListQuery,
        useAddFoodMutation,
        useAddIngredientMutation,
        useGetFoodQuery,
        useGetAllFoodListQuery,
        useAddFoodMediaMutation,
        useAddFoodArtMutation,
        useUpdateFoodMutation,
        useDeleteFoodMediaMutation,
        useDeleteFoodArtMutation,
        useDeleteFoodMutation,
        useDeleteIngredientMutation,
        useAddFoodProductMutation,
        useDeleteFoodProductMutation,
        useGetFoodByEntityQuery
        
      //  useUpdateItemMutation
    
    } = foodApi