import { PhotoButton } from "../photo-button/PhotoButton"

export const MovingCamera = (props:any) => {

const setStill = props.setStill
//const setImgSrc = props.setImgSrc
const src = "https://farm2everyone.com/img/front-img/shopska.mp4"
    return (
        <>
        <video className="camera-video" src={src} autoPlay muted></video> 
         <PhotoButton setStill={setStill} />
        </>
    )
}