import { Button } from "react-bootstrap"
import { useDeleteByteEntityMutation } from "../../../../api/planByteEndpoints"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"

export const ByteCard = (props:any) => {
const entity = props.entity
const index = props.index
const {id} = useParams()

let link = '/food/' + entity.id

if (entity.type == 1) {
    link = '/company/' + entity.id
} else 

if (entity.type == 2) {
    link = '/food/' + entity.id
} else 

if (entity.type == 3) {
    link = '/product/' + entity.id
} else 

if (entity.type == 4) {
    link = '/activity/' + entity.id
}

function seeIfVideo(fileName:string){

    const extension = fileName?.split('.').pop()
    if (extension == 'mp4') {
        return true
    }
    else {
        return false
    }
}

const src = entity?.media?.filter((media:any) => media.position == 0)[0]?.src


// const [deleteBe, {error:adError, isLoading: adLoading}] = useDeleteByteEntityMutation()

// const deleteEntity = () => {
//   //  alert("this should delete")
    
//     deleteBe({
//         id: id,
//         index: index
//     })
//     .unwrap()
//     .then((payload) => {
//         console.log('fulfilled', payload)
//     })
// }
    return(
        <>
        
        <div className="horizontal-card">
        <Link to={link} className="text-decoration-none text-reset"> 
            <div className="horizontal-card-img">
            {src && seeIfVideo(src) ? (<>
  

  <video src={process.env.REACT_APP_IMG + src} autoPlay loop muted></video> 

 </>) : src && !seeIfVideo(src) ? (<><img src={process.env.REACT_APP_IMG + src} /></>) 
         : null}
            </div>
            <div>{entity.name}</div>
            {/* <Button variant="outline-danger" size="sm"
            onClick={() => deleteEntity()}
            >delete</Button> */}
       </Link>
        </div>
        
        </>
    )
}