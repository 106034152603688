import { useState } from "react"
import { CameraIngredients } from "../camera-ingredients/CameraIngredients"

export const IngredientsLayer = () => {
    const [imgSrc, setImgSrc] = useState<string>()
    const [gifSrc, setGifSrc] = useState<string>()
    return(
        <>
        <>
      
      <div className="ingredient-image-container">
      <img className='camera-background' src={imgSrc} />
      <CameraIngredients setImgSrc={setImgSrc} setGifSrc={setGifSrc} />
      { gifSrc !== '' && gifSrc!= null ? <img className="gif-in-camera"  src={gifSrc} /> :null }
     

      </div>
      {
      
      }
     
      </>
        </>
    )
}