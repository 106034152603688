import { useState } from "react";
import { Form } from "react-bootstrap";

export const TripHealth = () => {
    const [isSwitchOn, setIsSwitchOn] = useState(true);

    const onSwitchAction = () => {
        console.log("changed")
      setIsSwitchOn(!isSwitchOn);
    };
    return(
        <>
        <div className="camera-image-container">
        <Form className="my-5">
      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="You are on vacation"
        onChange={()=>{setIsSwitchOn(false)}}
      />
      </Form>

{
    isSwitchOn ? (<img src="/images/other/calorie.jpg" />)
    : (<img src="/images/other/giphy.gif" />)
}
            
        </div>
        </>
    )
}