export const CO2 = () => {
    const src = "https://farm2everyone.com/img/impact-icons/animal.gif"

    return(<>
    <div className="co2-box">
        
            
           
            <div className="co2-sub-title">Carbon footprint</div>
            <div className="co2-container">
                <div className="co2-number-box">
               
                    <div className="co2-main-number">3.62</div>
                    <div className="co2-number-description">kg CO2 e/day</div>
                
                </div>
            
            <div className="co2-animal-box">
           
            <img className="co2-animal-image" src={src}/>
            </div>
               
                </div>
            
            </div>
    
    </>)



    
}