import { Food } from "../interfaces/Food";

import { springApi } from "./emptySpringApi";

export const plansApi = springApi.injectEndpoints({
    endpoints:(builder) => ({
        getPlansList: builder.query<any,{page:number, term:string}>({ 
            query: (arg) => {
                const { page, term } = arg;
                const size = 6
                console.log('arg: ', arg);
                return {
                  url: 'animal/plans/poochie',
                  params: { page, term, size },
                };
              },
              providesTags:['plans']

        }), 
        getAllPlansList: builder.query<any, void>({ 
          query: () => {
             // const { page, term, tag } = arg;
              const size = 6
              //console.log('arg: ', arg);
              return {
                url: 'animal/plans',
             //   params: { page, term, tag, size },
              };
            },
            providesTags:['plans']

      }), 
        getPlan: builder.query<any,string>({ 
          query: (id) => ({url:`animal/plans/${id}`}),
          providesTags:['plans', 'animals']
      }),
        addPlan: builder.mutation<any, any>({
            query: (newFood) => ({
                url:`animal/plans`,
                method: 'POST',
                body: newFood
            }),
            invalidatesTags:['plans']

        }),


        addBytePlan: builder.mutation<any, any>({
          query: (newMedia) => ({
            url:`animal/plan-bt`,
            method: 'POST',
            body: newMedia
        }),
        invalidatesTags:['plans']

      }),
      deleteBytePlan: builder.mutation<any, any>({
        query: (newMedia) => ({
          url:`animal/plan-bt`,
          method: 'PUT',
          body: newMedia
      }),
      invalidatesTags:['plans']

    }),

    updatePlan: builder.mutation({
      query: (food) => ({
        url:`animal/plans/${food.id}`,
        method: 'PUT',
        body: food
      }),
      invalidatesTags:['plans']
    }),
    deletePlan: builder.mutation({
      query: (id) => ({
        url:`animal/plan-bytes/${id}`,
        method: 'DELETE',
        
      }),
      invalidatesTags:['plans']
    }),

    //   addFoodImage: builder.mutation<Food, {foodId:string, position:number, file:any}>({
    //     query: (arg) => {
    //       const {foodId, position, file } = arg
    //       return {
    //         url: 'food-images',
    //         params: { foodId, position, file },
    //         method: 'POST',
    //         // headers: {
    //         //   "Content-Type": "multipart/form-data;",
    //         // },
    //         // prepareHeaders: (headers: any) => {
    //         //   headers.set("Content-Type", "multipart/form-data")
    //         //     return headers
    //         // },
    //       };
        
    //     },
    //     invalidatesTags:['foods']

    // })


        })
    })
    export const { 
      useAddBytePlanMutation,
      useAddPlanMutation,
      useDeleteBytePlanMutation,
      useDeletePlanMutation,
      useGetAllPlansListQuery,
      useGetPlanQuery,
      useGetPlansListQuery,
      useUpdatePlanMutation
    
    } = plansApi