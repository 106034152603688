import { useParams } from "react-router-dom"
import { useGetPlanQuery } from "../../../../../api/planEndpoints"
import { TripPlanMapMarker } from "../trip-plan-map-marker/TripPlanMapMarker"

export const TripPlanMapMarkers = (props: any) => {
   
    const { id } = useParams()
    const { data: plan, error, isFetching, isLoading } = useGetPlanQuery(id!)
    return(
        <>
                {error ? (<>Oh no, there was an error</>) :
        isLoading ? (<>Loading...</>) :
        plan ? (<>
        <h4 className="mx-2">{plan.title}</h4>
      
        
        {
            plan.bytes.map((byte:any, index:number) => (
             
               
               <TripPlanMapMarker index={index} byte={byte}/>
            ))
        }
        
        </>) : null}
        </>
    )
}