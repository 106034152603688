import { useState } from "react"
import { useAppSelector } from "../../app/hooks"
import { ExperimentCarousel } from "../experiment-carousel/ExperimentCarousel"
import { entitySt } from "./EntitySlice"
import { CarouselOuter } from "./carousel-outer/CarouselOuter"
import Drawer from "../common/drawer/Drawer"
import BtmSheet from "../common/bottom-sheet/BottomSheet"

export const ShowEntity = (props:any) => {

    // const entity = useAppSelector(entitySt)

    const entity = props.entity
    console.log("entity is..")
    console.log(entity)
    const [show, setShow] = useState(true)
    return(
        <>
        {/* <div className="w-100 position-relative"> */}
        <div className="entity-container">
          <CarouselOuter entity={entity}/>
        {/* <BtmSheet /> */}

        </div>
        </>
    )
}