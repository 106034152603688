import { APIProvider, Map } from "@vis.gl/react-google-maps"
import { useParams } from "react-router-dom"
import { useGetPlanQuery } from "../../../../api/planEndpoints"
import { TripPlanMapMarkers } from "./trip-plan-map-markers/TripPlanMapMarkers"

export const TripPlanMap = () => {
    const { id } = useParams()
    const { data: plan, error, isFetching, isLoading } = useGetPlanQuery(id!)
    
    let juje = plan?.bytes?.length

    const defaultProps = {
        center: {
          lat:42.49722101027686,
          lng:27.4782258654624
        },
        zoom: 15
      };
    return(
        <>
        <APIProvider apiKey={"AIzaSyCjy-5DgFTyC9ra-9DA0N0eGz3EqWB_7no"}>
    <Map
    mapId={'caee23a00e9eccf6'}
      style={{width: '100%', height: '100vh'}}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
    >



  {/* {
    locations.map((item:any, index:number) => (
        <AdvancedMarker 
        position={item.location} 
        key={index}
        onClick={(marker: any) => {console.log("whaaat???")
            console.log(item)
        }}
        >
        <img src={'/' + item.src} width={42} height={42} />
     </AdvancedMarker>
    ))
  } */}

<TripPlanMapMarkers />
        </Map>
  </APIProvider>
        </>
    )
}