import { AdvancedMarker } from "@vis.gl/react-google-maps"

export const TripPlanMapMarker = (props:any) => {

    const byte = props.byte
    const index = props.index
    const position = {
        lat: byte.latitude,
        lng: byte.longitude
    }
    return(
        <>
        {byte.animal? (<>
            <AdvancedMarker 
        position={position} 
        key={index}
        onClick={(marker: any) => {console.log("whaaat???")
           
        }}
        >
        <img src={process.env.REACT_APP_IMG + byte.animal.src} width={82} height={82} />
     </AdvancedMarker>
        </>) : null
           

}
        </>
    )

}