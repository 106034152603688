import { ArrowBack } from "react-ionicons"
import { GreenPractices } from "../../trip-impact/green-practices/GreenPractices"

export const TripFoodImpact = (props:any) => {
    const setView = props.setView
    const practices = [
        {
            id: 1,
            name: 'Rainfed agriculture',
            icon: 'rain.gif'
        },
        {
            id: 2,
            name: 'Drip irrigation',
              icon: 'drip.gif'
        },
        {
            id: 3,
            name: 'Drought-resistant crops',
            icon: 'drought.gif'
        },
        {
            id: 4,
            name: 'Np-till farming',
            icon: 'no-till.gif'
        },
        {
            id: 5,
            name: 'Crop residue left in field',
            icon: 'residue.gif'
        },
        {
            id: 6,
            name: 'Crop rotation',
            icon: 'rotation.gif'
        },
        {
            id: 7,
            name: 'Cover crops',
            icon: 'cover.gif'
        },
        {
            id: 8,
            name: 'Compost',
            icon: 'compost.gif'
        },
 
        {
            id: 6,
            name: 'Goats fed on natural pastures and shrublands',
            icon: 'goat.gif'
        },
    ]
    return(
        <>
            <div className="top-band">
        <ArrowBack
                color={'#282828'}
              
                height="28px"
                width="28px"
                 onClick={() => {setView(1)}}
             />
        
        </div>
        <h4>Green practices</h4>
        <GreenPractices practices={practices} />
        </>
    )
}