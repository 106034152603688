import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"

export const ByteOne = (props:any) => {
    const entity = props.entity
   
console.log("entity is...")
console.log(entity)

let link = '/food/' + entity.id

if (entity.type == 1) {
    link = '/company/' + entity.id
} else 

if (entity.type == 2) {
    link = '/food/' + entity.id
} else 

if (entity.type == 3) {
    link = '/product/' + entity.id
} else 

if (entity.type == 4) {
    link = '/activity/' + entity.id
}

    const src = entity?.media?.filter((media:any) => media.position == 0)[0]?.src

    function seeIfVideo(fileName:string){

        const extension = fileName?.split('.').pop()
        if (extension == 'mp4') {
            return true
        }
        else {
            return false
        }
    }
    return(
        <>
       
        <div className="w-100 byteone-container">
        <Link to={link} className="text-decoration-none text-reset">
        {/* <h6>{entity.name}</h6> */}
        {src && seeIfVideo(src) ? (<>
  

  <video src={process.env.REACT_APP_IMG + src} autoPlay loop muted></video> 

 </>) : src && !seeIfVideo(process.env.REACT_APP_IMG + src) ? (<><img src={process.env.REACT_APP_IMG + src} /></>) 
         : null}
         <h6>{entity.name}</h6>
            {/* <Button variant="outline-danger" size="sm">delete</Button> */}
            </Link>
        </div>

        </>
    )
}