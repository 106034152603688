import { useState } from "react"
import { CameraFoodCarousel } from "../camera-food-carousel/CameraFoodCarousel"
import { IngredientsLayer } from "../ingredients-layer/IngredientsLayer"

export const StillCamera = (props:any) => {
   const imgSrc = props.imgSrc
   const setImgSrc = props.setImgSrc
    const [isSelected, setIsSelected] = useState(false)
    const src = "https://farm2everyone.com/img/front-img/shopska.jpg"

   return(
<>
      
        <div className="camera-image-container">
        <img src={src} />

        </div>
        {
            isSelected ? (
            <IngredientsLayer />
        ) : (
                <CameraFoodCarousel setImgSrc={setImgSrc} setIsSelected={setIsSelected} />
            )
        }
       
        </>
    )
}