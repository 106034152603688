import { Button, Container } from "react-bootstrap"
import { PlanByteOne } from "../../plan-byte-look/plan-byte-one/PlanByteOne"
import { PlanByteMany } from "../../plan-byte-look/plan-byte-many/PlanByteMany"
import { ByteOne } from "../byte-one/ByteOne"
import { ByteMany } from "../byte-many/ByteMany"
import { useDeleteBytePlanMutation } from "../../../api/planEndpoints"
import { useParams } from "react-router-dom"

export const ByteInPlan = (props:any) => {

    const byte = props.byte
    let juje = byte?.entities?.length
    const index= props.index
    const { id } = useParams()

    const [deleteBe, {error:adError, isLoading: adLoading}] = useDeleteBytePlanMutation()

const deleteEntity = () => {
  //  alert("this should delete")
    
    deleteBe({
        id: id,
        index: index
    })
    .unwrap()
    .then((payload) => {
        console.log('fulfilled', payload)
    })
}
    return(
        <>
        <Container className="mt-2 p-1 border-bottom">
        <h6>{byte.title}</h6>
        {
            juje == 1 ? (<>
            <ByteOne entity={byte.entities[0]}/></>) :  
            juje > 1 ? (<><ByteMany entities={byte.entities}/></>) :null
        }
   
        </Container>
        </>
    )
}