import { BaseQueryApi, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { useAppDispatch } from '../app/hooks'
// import { logout } from '../features/login/authSlice'


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
//  credentials: 'include',
//  mode: 'cors',
  // prepareHeaders: (headers, { getState }) => {
  //     const user = localStorage.getItem("user")
  //     if (user) {
  //       const token = JSON.parse(user).token
  //         headers.set("Authorization", `Bearer ${token}`)
  //     }
  //     return headers
  // }
})

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error?.status === 401 ) {
  
      //    api.dispatch(logout())
      }
  

  return result
}
// initialize an empty api service that we'll inject endpoints into later as needed
export const springApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  reducerPath: 'sqlApi',
  tagTypes: ['items','activities', 'fun', 'plans', 'entities', 'animals', 'planBytes', 'foods', 'meals', 'art', 'producers', 'deliveries', 'products', 'slots', 'inventory', 'users', 'notifications', 'orders', 'organizations', 'racuns', 'food-instance', 'toyz', 'companies']
})