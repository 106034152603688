import { useNavigate } from "react-router-dom"

export const TripPlanCard = (props:any) => {

    const plan = props.plan

    const navigate = useNavigate()
    return(
        <>
        <div className="plan-card">
    
            <div className="plan-card-content">
            
                {plan.title}
           

           <div className="plan-card-button"
           onClick={() => { navigate(`/plan/${plan.id}`)  }}
           >
                Explore
           </div>
            </div>
            <div>
 
            <img className="plan-card-image" src={process.env.REACT_APP_IMG + plan.animal?.src}/>
            </div>

       
        </div>
        </>
    )
}