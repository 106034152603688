import { LocationOutline, Pulse } from "react-ionicons"
import { Link } from "react-router-dom"

export const Measure = () => {

    return(
        <>
        <Link to="/health">
       <div className="measure-container float-end mt-1 me-2">
         
          <Pulse
                color={'#282828'}
              
                height="28px"
                width="28px"
                // onClick={() => alert('Hi!')}
             />
        </div>
        </Link>
        </>
    )
}