import { LocationOutline, LogoNodejs, Map } from "react-ionicons"

export const MapTab = () => {
    return(
        <div className="bottom-tab">

                <LocationOutline
                color={'#ffffff'}
              
                height="28px"
                width="28px"
                // onClick={() => alert('Hi!')}
                />
               <div>Map</div> 
        </div>
    )
}