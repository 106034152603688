export const CameraFoodCard = (props:any) => {
    const marker = props.marker
    const index = props.index
   
    const setImgSrc = props.setImgSrc
    const setIsSelected = props.setIsSelected

    const src = "https://farm2everyone.com/img/front-img/" + props.marker.image
   
    const handlePress = () => {
      // setImgSrc(src)
      setIsSelected(true)
    }
    return(
        <>
                <div className="camera-food-card" key={index}
                onClick={() => {handlePress()}}
                >
        <img src={src}
          className="camera-food-card-image"
        />
        <div className="camera-food-card-text-content">
          <div className="camera-food-card-title">{marker.title}</div>
          {/* <StarRating ratings={marker.rating} reviews={marker.reviews} /> */}
          <div className="camera-food-card-description">{marker.description}</div>
       
        </div>
      </div>
        </>
    )
}