import { Key } from "react"
import { Carousel } from "react-bootstrap"

export const CarouselInner = (props:any) => {
const sources = props.sources
console.log("sources are...")
console.log(sources)
    return(
        <>
                <Carousel>
      
        {sources?.map((src: { type: number; media: string | undefined }, i: number) => (
        <Carousel.Item key={i}>
            <div className="add-slide">
            {src.type == 1 ? (<video src={src.media} autoPlay loop muted></video> ) :
            src.type == 2 ? (<img  src={src.media} />) : (<>not getting the type</>)
            }
            </div>
        </Carousel.Item>
      ))}
           
           
       
        </Carousel>
        </>
    )
}