
import { CameraOutline, LogoNodejs, Map, PawOutline, RestaurantOutline } from "react-ionicons"

export const ImpactTab = () => {
    return(
        <div className="bottom-tab">

                <PawOutline
                color={'#ffffff'}
              
                height="28px"
                width="28px"
                // onClick={() => alert('Hi!')}
                />
               <div>Impact</div> 
        </div>
    )
}