import { useGetAllFunListQuery } from "../../../api/funByteEndpoints"
import { Measure } from "../measure/Measure"
import { FunItem } from "./fun-item/FunItem"

export const TripFun = () => {

    const { data:items, error, isFetching, isLoading } = useGetAllFunListQuery()

    return(
        <>
        <div className="header-bar">
            <Measure />
        </div>
             {error ? (<>Oh no, there was an error</>) :
        isLoading ? (<>Loading...</>) :
        items ? (<>

        <div className="fun-container px-2 footer-container header-container">
            {
                 items.map((item:any, index:number) => (
                    <FunItem item={item} key={index} index={index}/>
                ))
            }
        </div>
        
        </>) : null }
        </>
    )
}