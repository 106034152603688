import { useParams } from "react-router-dom"
import { useGetFoodByEntityQuery } from "../../../../api/foodEndpoints"
import { ArrowBack } from "react-ionicons"
import { TripFoodIngredientMap } from "../trip-food-ingredient-map/TripFoodIngredientMap"

export const TripFoodIngredients = (props:any) => {

    const { id } = useParams()

    const setView = props.setView
     const { data: food, error, isFetching, isLoading } = useGetFoodByEntityQuery(id!)

    return(
        <>
        <div className="top-band">
        <ArrowBack
                color={'grey'}
              
                height="20px"
                width="20px"
                 onClick={() => {setView(1)}}
             />
        
        </div>
        <div className="food-ingredient-container ms-3">
            {error ? (<>Oh no, there was an error</>) :
        isLoading ? (<>Loading...</>) :
        food && food.ingredients && food.ingredients.length > 0 ? (<>
        {
            food.ingredients.map((ingredient:any, index:number) => (
                <div>{ingredient.item.itemName}</div>
            ))
        }
         </>) : null }
         </div>

        <TripFoodIngredientMap />
        
        </>
    )
}