import { SetStateAction, useState } from "react";
import { Carousel } from "react-bootstrap"
import { useAppSelector } from "../../../app/hooks";
import { entitySt } from "../EntitySlice";
import { CarouselInner } from "../carousel-inner/CarouselInner";

export const CarouselOuter = (props:any) => {

    // const [index, setIndex] = useState(0);

    // const handleSelect = (selectedIndex: SetStateAction<number>) => {
    //   setIndex(selectedIndex);
    // };

    // const entity = useAppSelector(entitySt)
    const entity = props.entity

    

    const media0 =  entity.media?.filter((media:any) => media.position == 0)[0]
    const media1 =  entity.media?.filter((media:any) => media.position == 1)[0]
    const media2 =  entity.media?.filter((media:any) => media.position == 2)[0]
    const media3 =  entity.media?.filter((media:any) => media.position == 3)[0]
    const media4 =  entity.media?.filter((media:any) => media.position == 4)[0]
    const media5 =  entity.media?.filter((media:any) => media.position == 5)[0]
    const media6 =  entity.media?.filter((media:any) => media.position == 6)[0]
    const media7 =  entity.media?.filter((media:any) => media.position == 7)[0]
    const media8 =  entity.media?.filter((media:any) => media.position == 8)[0]
    const media9 =  entity.media?.filter((media:any) => media.position == 9)[0]
   
const allMedia = [media0, media1, media2, media3, media4, media5, media6, media7, media8, media9]

let sources: any[] = []

allMedia.forEach( media => {
    if(media?.src){
        const source = process.env.REACT_APP_IMG + media?.src
        let mymedia 
        const extension = media.src?.split('.').pop()
        if (extension == 'mp4') {
            mymedia = 1
            
        }
        else{
            mymedia = 2
        }
        sources.push(
            {type: mymedia, media: source}
            )
    }
}
)
console.log(sources)
    return(
        <>
<CarouselInner sources={sources} />
        </>
    )
  
    
}