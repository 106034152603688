import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Tag } from "../../interfaces/Tag"

interface SearchPage {
    term: string,
    page: number,
    tag: Tag,
}
const initialState: SearchPage = {
    term: "",
    page: 0,
    tag: {
        id: "",
        tag:"",
        
        color:""
    }
}

export const itemSearchSlice = createSlice({
    name: "ingredientSearch",
    initialState,
    reducers: {
        updateItemSearch: (state, action: PayloadAction<SearchPage>) => {
            state.term = action.payload.term
            state.page = action.payload.page
            state.tag = action.payload.tag
        },
        resetSearch:() => {
            return initialState
        }
    }
})


export const { updateItemSearch, resetSearch } = itemSearchSlice.actions
export const itemSearch = (state: RootState) => state.itemSearch
export default itemSearchSlice.reducer