import { Food } from "../interfaces/Food";

import { springApi } from "./emptySpringApi";

export const funApi = springApi.injectEndpoints({
    endpoints:(builder) => ({
        getFunList: builder.query<any,{page:number, term:string}>({ 
            query: (arg) => {
                const { page, term } = arg;
                const size = 6
                console.log('arg: ', arg);
                return {
                  url: 'animal/plan-bytes/poochie',
                  params: { page, term, size },
                };
              },
              providesTags:['fun']

        }), 
        getAllFunList: builder.query<any, void>({ 
          query: () => {
             // const { page, term, tag } = arg;
              const size = 6
              //console.log('arg: ', arg);
              return {
                url: 'animal/fun/juje',
             //   params: { page, term, tag, size },
              };
            },
            providesTags:['fun']

      }), 

        // addPlanByte: builder.mutation<any, any>({
        //     query: (newFood) => ({
        //         url:`animal/plan-bytes`,
        //         method: 'POST',
        //         body: newFood
        //     }),
        //     invalidatesTags:['planBytes']

        // }),

        addFunByte: builder.mutation({
            query: (id) => ({
              url:`animal/fun/${id}`,
              method: 'POST',
              
            }),
            invalidatesTags:['fun']
          }),


    deleteFunByte: builder.mutation({
      query: (id) => ({
        url:`animal/fun/${id}`,
        method: 'DELETE',
        
      }),
      invalidatesTags:['fun']
    }),

    //   addFoodImage: builder.mutation<Food, {foodId:string, position:number, file:any}>({
    //     query: (arg) => {
    //       const {foodId, position, file } = arg
    //       return {
    //         url: 'food-images',
    //         params: { foodId, position, file },
    //         method: 'POST',
    //         // headers: {
    //         //   "Content-Type": "multipart/form-data;",
    //         // },
    //         // prepareHeaders: (headers: any) => {
    //         //   headers.set("Content-Type", "multipart/form-data")
    //         //     return headers
    //         // },
    //       };
        
    //     },
    //     invalidatesTags:['foods']

    // })


        })
    })
    export const { 
        useAddFunByteMutation,
        useDeleteFunByteMutation,
        useGetAllFunListQuery
    
    } = funApi